@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-Thin.eot');
    src: url('./fonts/AvenirNextLTPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Thin.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Thin.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-ThinIt.eot');
    src: url('./fonts/AvenirNextLTPro-ThinIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-ThinIt.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-ThinIt.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-ThinIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-Light.eot');
    src: url('./fonts/AvenirNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Light.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Light.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-LightIt.eot');
    src: url('./fonts/AvenirNextLTPro-LightIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-LightIt.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-LightIt.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./fonts/AvenirNextLTPro-Regular.eot');
    src: url('./fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Regular.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-It.eot');
    src: url('./fonts/AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-It.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-It.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}



@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./fonts/AvenirNextLTPro-Medium.eot');
    src: url('./fonts/AvenirNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Medium.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Medium.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-MediumIt.eot');
    src: url('./fonts/AvenirNextLTPro-MediumIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-MediumIt.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-MediumIt.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}



@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-DemiIt.eot');
    src: url('./fonts/AvenirNextLTPro-DemiIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-DemiIt.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-DemiIt.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-DemiIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-Demi.eot');
    src: url('./fonts/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Demi.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-Bold.eot');
    src: url('./fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Bold.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-BoldIt.eot');
    src: url('./fonts/AvenirNextLTPro-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-BoldIt.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-BoldIt.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./fonts/AvenirNextLTPro-Heavy.eot');
    src: url('./fonts/AvenirNextLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-Heavy.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-Heavy.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('./fonts/AvenirNextLTPro-HeavyIt.eot');
    src: url('./fonts/AvenirNextLTPro-HeavyIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvenirNextLTPro-HeavyIt.woff2') format('woff2'),
        url('./fonts/AvenirNextLTPro-HeavyIt.woff') format('woff'),
        url('./fonts/AvenirNextLTPro-HeavyIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
